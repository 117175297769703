type LoggerMethods =
    | 'debug'
    | 'log'
    | 'warn'
    | 'error'
    | 'groupCollapsed'
    | 'groupEnd'
    | 'info';

let loggerInitialized = false;

const initializeLogger = async () => {
    if (loggerInitialized) {
        return;
    }

    const methodsToRelay = [
        'clear',
        'debug',
        'error',
        'group',
        'groupCollapsed',
        'groupEnd',
        'info',
        'log',
        'warn'
    ];

    for (const method of methodsToRelay) {
        const globalMethod = console[method];

        console[method] = async (...args: Array<any>) => {
            const clients = await self.clients.matchAll();

            if (clients.length === 0) {
                globalMethod(...args);
            }

            for (const client of clients) {
                client.postMessage(JSON.stringify({
                    type: 'ConsoleOperation',
                    method: method,
                    args: args
                }));
            }
        }
    }

    loggerInitialized = true;
};

const Logger = ((() => {
    let inGroup = false;

    const methodToColorMap: {[methodName: string]: string | null} = {
        debug: `#7f8c8d`,
        log: `#2ecc71`,
        warn: `#f39c12`,
        error: `#c0392b`,
        info: '#2ecc71',
        groupCollapsed: `#3498db`,
        groupEnd: null,
    };

    const print = function (method: LoggerMethods, args: any[]) {
        if (method === 'groupCollapsed') {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                console[method](...args);
                return;
            }
        }

        const styles = [
            `background: ${methodToColorMap[method]!}`,
            `border-radius: 0.5em`,
            `color: white`,
            `font-weight: bold`,
            `padding: 2px 0.5em`,
        ];

        const logPrefix = inGroup ? [] : ['%cOmega 365 Service Worker', styles.join(';')];

        console[method](...logPrefix, ...args);

        if (method === 'groupCollapsed') {
            inGroup = true;
        }

        if (method === 'groupEnd') {
            inGroup = false;
        }
    };

    const api: {[methodName: string]: Function} = {};
    const loggerMethods = Object.keys(methodToColorMap);

    for (const key of loggerMethods) {
        const method = key as LoggerMethods;

        api[method] = (...args: any[]) => {
            print(method, args);
        };
    }

    return api as unknown;
})()) as Console;

initializeLogger();

export { Logger };
